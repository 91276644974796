// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tour-page-index-jsx": () => import("/opt/render/project/src/src/templates/TourPage/index.jsx" /* webpackChunkName: "component---src-templates-tour-page-index-jsx" */),
  "component---src-templates-advanture-description-index-jsx": () => import("/opt/render/project/src/src/templates/AdvantureDescription/index.jsx" /* webpackChunkName: "component---src-templates-advanture-description-index-jsx" */),
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-multibox-js": () => import("/opt/render/project/src/src/pages/multibox.js" /* webpackChunkName: "component---src-pages-multibox-js" */),
  "component---src-pages-page-2-js": () => import("/opt/render/project/src/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

